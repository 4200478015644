@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600i,700,800&display=swap');
@import "../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

@import "../node_modules/bootstrap/scss/functions";

//Overrides - bootstrap
$body-bg: #fcfcfc;
$body-color: #333;
$primary: #00a14d;
$secondary: #e5f5ed;
$warning: #f5a623;
$light: #fafafa;
$dark: #333;
$min-contrast-ratio: 3.38;

$nav-pills-link-active-bg: $secondary;

$breadcrumb-bg: $light;
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-x: 0.5rem;
$breadcrumb-padding-y: 0.5rem;

//Overrides - react-big-calendar
$event-bg: $primary;
$event-border: darken($event-bg, 10%);
$event-outline: lighten($event-bg, 20%);

//bootstrap required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/root";

//Optional
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
//@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
//@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
//@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
//@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";

@import "~react-toastify/dist/ReactToastify.css";
@import "~react-big-calendar/lib/sass/styles";

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-link {
  background: white;
}

.btn {
  font-weight: bold;
}

.breadcrumb {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.bg-dark.modal-header {
  .close {
    color: $light;
  }
}

#root {
  .sfm-toast {
    color: $body-color;
  }

  .sfm-progress-bar {
    background: $primary;
  }
}

.sfm-label,
label.form-label {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  color: #999;
}

div.sfm-select__value-container {
  display:flex;
}

//NOTE:TJH - borrowed heavily from https://github.com/twbs/bootstrap/blob/main/scss/mixins/_forms.scss#L5
@mixin sfm-validation-state-selector($state) {
  @if ($state == "valid" or $state == "invalid") {
    .was-validated #{if(&, "&", "")}:sfm-is-#{$state},
    #{if(&, "&", "")}.sfm-is-#{$state} {
      @content;
    }
  } @else {
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
}

@mixin sfm-validation-state(
  $state,
  $color,
  $icon,
  $tooltip-color: color-contrast($color),
  $tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity),
  $focus-box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($color, $input-btn-focus-color-opacity),
  $border-color: $color
) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    margin-top: .1rem;
    @include font-size($form-feedback-tooltip-font-size);
    line-height: $form-feedback-tooltip-line-height;
    color: $tooltip-color;
    background-color: $tooltip-bg-color;
    @include border-radius($form-feedback-tooltip-border-radius);
  }

  @include sfm-validation-state-selector($state) {
    ~ .#{$state}-feedback,
    ~ #{$state}-tooltip {
      display: block;
    }
  }

  .sfm-select {
    @include sfm-validation-state-selector($state) {
      border-color: $border-color;

      @if $enable-validation-icons {
        &:not([multiple]):not([size]),
        &:not([multiple])[size="1"] {
          --#{$prefix}form-select-bg-icon: #{escape-svg($icon)};
          padding-right: $form-select-feedback-icon-padding-end;
          background-position: $form-select-bg-position, $form-select-feedback-icon-position;
          background-size: $form-select-bg-size, $form-select-feedback-icon-size;
        }
      }

      &:focus {
        border-color: $border-color;
        box-shadow: $focus-box-shadow;
      }
    }
  }

  .sfm-date {
    .SingleDatePickerInput {
      background-color: $body-bg;
    }

    @include sfm-validation-state-selector($state) {
      .react-daterange-picker {
        border-color: $color;
        background-image: $icon;
        background-repeat: no-repeat;
        background-position: center right $input-height-inner-quarter;
        background-size: $input-height-inner-half $input-height-inner-half;
      }

      &:focus {
        border-color: $primary;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }

      ~ .#{$state}-feedback {
        display: block;
      }
    }
  }

  .sfm-file {
    @include sfm-validation-state-selector($state) {
      background-image: $icon;
      background-repeat: no-repeat;
      padding-right: $input-height-inner;
      background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
      background-size: $input-height-inner-half $input-height-inner-half;

      ~ .#{$state}-feedback {
        display: block;
      }
    }
  }
}

@each $state, $data in $form-validation-states {
  @include sfm-validation-state($state, $data...);
}

.sfm-filter-pill {
  @extend .border;
  @extend .border-primary;
  @extend .text-muted;
  @extend .pb-1;
  @extend .ps-3;
  padding-top: 0.3rem;

  button {
    @extend .pe-0;
    @extend .py-1;
    @extend .border-0;
    @extend .text-muted;

    line-height: 12px;
    padding-left: 0.4rem;
    margin-right: -0.1rem;
  }
}

address[rel="author"] {
  font-weight: bold;
  display: inline;
  margin: 1rem;
}

.sfm-input {
  @extend .form-control;

  border-color: $body-color;

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 1px $primary;
  }
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;
}

.sfm-time-sheet-popover {
  @extend .shadow;
  background-color: $secondary;
  border: 1px solid $border-color;
  width: max-content;
  padding: 5px;
  border-radius: $border-radius;
  font-size: 90%;
  z-index: 100;
}

.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
}

.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-daterange-picker__inputGroup__divider,
.react-daterange-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-daterange-picker__button:enabled {
  cursor: pointer;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #0078d7;
}

.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}

.react-daterange-picker__button svg {
  display: inherit;
}

.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 100;
}

.react-daterange-picker__calendar--closed {
  display: none;
}

.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 100;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: $secondary;
}

.react-calendar__tile--now {
  background: $secondary;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $secondary;
}

.react-calendar__tile--hasActive {
  background: $primary;
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: $primary;
  color: white;
}

.react-calendar__tile--active {
  background: $primary;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $primary;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.card-body {
  pre {
    white-space: pre-wrap;
    font-family: 'Montserrat', sans-serif;
  }
}

@media print {
  body {
    font-size: $font-size-lg;
    font-weight: 500 !important;
  }

  .page-break {
    break-before: auto !important;
    page-break-before: auto !important;
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }

  .card-header {
    font-weight: bold;
    padding: 0.5rem 1.0rem;
  }
  .card-footer {
    padding: 0.5rem 1.0rem;
  }

  .card-body {
    pre {
      margin-bottom: 0.3rem;
    }

    .table-sm {
      tr {
        td {
          padding: 0.2rem;
        }
      }
    }
  }
}

//react-big-calendar mods
//.rbc-allday-cell {
//  display: none;
//}
//.rbc-time-view .rbc-header {
//  border-bottom: none;
//}